// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ApiLoading from './utils/axios';
import {ConfirmProvider} from "material-ui-confirm";
import { useEffect } from 'react';

/* Git Info */
import GitInfo from 'react-git-info/macro';

/* SENTRY */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { isVestaAdminApp, isVestaAdminAppIndiaAz } from './utils/vesta';

Sentry.init({
  dsn: "https://be013c2b89f343958af237b26f131013@o198125.ingest.sentry.io/6467732",
  // integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  // WARNING! We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: 0.005,
});

/* Git info (cont) */
const gitInfo = GitInfo();
console.log(gitInfo?.branch,gitInfo?.commit?.shortHash,gitInfo?.commit?.message,gitInfo?.commit?.date);

// ----------------------------------------------------------------------

export default function App() {

  /* Analytics/Tracking MS Clarity (For VESTA Admin App) * /
  useEffect(() => {
    if( !(isVestaAdminApp || isVestaAdminAppIndiaAz) ) return;
    const tagId = `m3onq2em01`;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.clarity.ms/tag/${tagId}`;
    document.head.appendChild(script);
    return () => { document.head.removeChild(script); };
  }, []);
  /* */

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ConfirmProvider> {/* Custom: Added 02 Jun 22 */}
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <ApiLoading />
                <Router />
                </ConfirmProvider>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
