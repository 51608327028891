import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
// sections
import { Box, Card, Container, Stack, Typography } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import { useForm } from 'react-hook-form';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import SearchAndSelect from '../dynamic-components/SearchAndSelect';
import { axiosInstance as axios } from 'src/utils/axios';
import { useTheme } from '@mui/material/styles';

export default function DeptManagement() {

    const theme = useTheme();

  // const [file,setFile]= useState<Image | null>(null);
  const { themeStretch } = useSettings();

  // Selected
  const [selectedOrg, setSelectedOrg] = useState<any>();
  const [selectedDept, setSelectedDept] = useState<any>();

  // Options
  const [departments, setDepartments] = useState<any>();

  const deptSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Name is required'),
  });

  type FormValues = {
    name: string;
  };

  const defaultValues = {
    name: '',
  };

  const methods = useForm<FormValues>({
    resolver: yupResolver(deptSchema),
    defaultValues,
  });

  const {
    register,
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;
  const values = watch();

  //set form values on select
  useEffect(() => {
      getDepartments(selectedOrg?.id);
  },[selectedOrg])

  const getDepartments = async (orgId: number) => {
    let r = await axios.get(`hotel/${orgId}/desks`);
    setDepartments(r.data);
  }

  const onSubmitHandler = async(data: FormValues) => {
      let r= await axios.post('/admin/desk/update', {
        id: selectedDept?.id,
        name: data.name,
      }).then((res) => {
        getDepartments(selectedOrg?.id); // refresh
      });
  };

  const handleSelect=(data:any)=>{
    console.log(data)
    setSelectedOrg(data)
  }

  const handleSelectDesk=(dept:any)=>{
    setSelectedDept(dept);
    setValue('name', dept?.name??'');
  }

  return (
    <Page title="Manage Departments [Admin]">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Departments"
          links={[
            { name: 'Organization', href: '#' },
            { name: 'Manage Depts.', href: '' },
          ]}
        />

        <Box>
          <SearchAndSelect model='hotels' type="single" handleSelect={handleSelect} outputType="object" />
        </Box>

        { (departments && departments.length) ? (
          <Box sx={{py:2}}>
            <SearchAndSelect model='desks'
              type="single" handleSelect={handleSelectDesk} outputType="object"
              dropdownOptions={departments} label="Select Dept" />
          </Box>
        ) : <></> }

        {selectedOrg && (
          <Box>
            <Card sx={{ p: 2, ml: 2, mt: 3 }}>

              <Typography sx={{mb:2}} variant="h5" component="h5">
                Dept: {selectedDept?.name}
              </Typography>

              <Typography sx={{mb:2}}>Update/Edit Details</Typography>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandler)}>
                <Stack spacing={1.5} mb={3}>
                  {/* FIELDS */}
                  <RHFTextField name="name" label="Name" />
                </Stack>

                <Stack direction="row" spacing={5}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                  >
                    Update
                  </LoadingButton>
                </Stack>

              </FormProvider>
            </Card>
          </Box>
        )}

      </Container>
    </Page>
  );
}
