// @mui
import { Stack, Button, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {

  const { user } = useAuth();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >

      {/*<DocIllustration sx={{ width: 1 }} />*/}

      <div>
        <Typography gutterBottom variant="subtitle1" sx={{mb:0.64}}>
          Hi, {user?.fname}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help? Contact us
        </Typography>
      </div>

      <Button href={'mailto:support@checkinn.co'} target="_blank" rel="noopener" variant="text">
        Reach out
      </Button>

    </Stack>
  );
}
