import Page from '../../../components/Page';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../../../components/chart';
import { ApexOptions } from 'apexcharts';

interface HotelRequestsCount {
  [hotelName: string]: number;
}

interface ChartSeries {
  name: string;
  data: number[];
}

interface ChartData {
  series: ChartSeries[];
  options: ApexOptions;
}

export default function IntershipFSD() {

  /* Task: Working demo */
  const apiEndpoint = 'https://checkinn.co/api/v1/int/requests';
  const [apiData, setApiData] = useState([]);
  const [chartData, setChartData] = useState<ChartData>({
    series: [],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      title: {
        text: 'Requests per Hotel',
        align: 'center',
      },
    },
  });

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
  });

  useEffect(() => {
    axios.get(apiEndpoint)
      .then(res => {

        let requests = res.data?.requests ?? [];

        setApiData(requests);

        const hotelRequestCounts: HotelRequestsCount = requests.reduce((acc: HotelRequestsCount, request: any) => {
          const hotelName = request.hotel.name;
          acc[hotelName] = (acc[hotelName] || 0) + 1;
          return acc;
        }, {});

        const categories = Object.keys(hotelRequestCounts);
        const seriesData: number[] = Object.values(hotelRequestCounts);

        setChartData((prevData) => ({
          ...prevData,
          series: [{ name: 'Requests', data: seriesData }],
          options: {
            ...prevData.options,
            xaxis: { ...prevData.options.xaxis, categories },
          },
        }));

      })
      .catch(err => {
        console.error(err);
      });
  }, []);


  return (<Page title="Full-stack dev iternship | VESTA">
    <Container sx={{ mt: 14, mb: 12, position: 'relative' }}>

      <Typography variant="h4" sx={{ mb: 3 }}>Full-stack developer / internship 2024</Typography>

      <Grid container spacing={5}>

        <Grid item xs={12}>
          <p>
            Create a simple React app to fetch and display the data from the API endpoint.
            <br />
            API Endpoint: <code>GET</code> <a href="https://checkinn.co/api/v1/int/requests" target="api">https://checkinn.co/api/v1/int/requests</a>
            <br />
            <small>PS: You could <a href="https://checkinn.co/uploads/json/requests-sample.json" target="api-download">download the json</a> for quicker local development and testing</small>
          </p>

          <Box sx={{ p: 2, mt: 3, mb: 1, borderRadius: 2, border: '1px dashed #aaa', minHeight: 500 }}>
            <h4>Expected output</h4>
            <small>The app should have this working chart in the exact center(horiz. and vert. center) of the
              page </small>

            <Box justifyContent="center" alignItems="center" width={680} height={'100%'} sx={{
              pt: 8,
              pb: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
              margin: 'auto',
              lineHeight: 1.75,
            }}>
              { (chartData) ?
                  <ReactApexChart series={chartData?.series} height={320} width={680} options={chartData?.options} /> :
                  <p>Loading chart...</p>
              }
              <br />
              Total requests: {chartData.series[0]?.data.reduce((a, b) => a + b, 0)}
              <br/>
              <small>
              List of <em>unique</em> department names across all Hotels: {apiData?.map((req: any) => req.desk.name).filter((v: any, i: any, a: any) => a.indexOf(v) === i).join(', ')}
              </small>
            </Box>

          </Box>

        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 3 }}>Pointers</Typography>
          <Box px={4}>
            <ul>
              <li>A <strong>React</strong> app with <em>suggested</em> packages: <code>axios</code> (for API
                calls), <code>ReactApexCharts</code> (for charts)
              </li>
              <li>Must use ES6 features like <code>map, filter</code> etc. for manipulating the API response</li>
              <li>Must use CSS3 features like flex and/or grid for centering. Responsiveness is <em>not</em> required</li>
              <li>Code quality and thought process would be evaluated</li>
              <li>Do not hardcode anything. For testing, the input data would be changed (more orgs, more depts ...)
              </li>
              <li>Use git for version control</li>
              <li>The task should be completed in 3-4 hours max</li>
            </ul>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 3 }}>Submission</Typography>
          <Box px={4}>
            <p>Share your links with any other details you wish to share, to jobs@teamvesta.com. The mail should contain the following links: </p>
            <br />
            <ol>
              <li>Hosted app link
                <br /><small>The app should have the <em>expected output</em> from above.
                  Hosting could be on any public static hosting service like GitHub pages, Azure static app, Vercel etc.. </small>
              </li>
              <li>Github link to the code
                <br /><small>Access to your app's code.</small>
              </li>
            </ol>
          </Box>
        </Grid>

      </Grid>
    </Container>
    <style>
      {`
      code {
        background-color: #f5f5f5;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        color: #000;
      }
      `}
    </style>
  </Page>);
}

