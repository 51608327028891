import { useState, useMemo, useEffect } from "react";
import { setup } from 'axios-cache-adapter';
// config
import { HOST_API } from '../config';
import { useSnackbar, VariantType } from "notistack";

import NProgress from 'nprogress';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

const CACHE_URLs_MATCHING = [/cache=true/]; // regex of the route that should be cached;
export function shouldCacheRequest(req:any) {
    const { url } = req;
    const urlMapped = CACHE_URLs_MATCHING.map(pattern => {
        const patternRegexp = new RegExp(pattern);
        return patternRegexp.test(url);
    });
    const shouldCached = urlMapped.includes(true);
    return !shouldCached; // `false` caches
}

export const axiosInstance = setup({
    baseURL: HOST_API,
    validateStatus: function (status) {
        return (status >= 200 && status < 300);
    },
    // `axios-cache-adapter` options
    cache: {
        maxAge: 5*(60*1000), // enter an `integer` in minutes; final value is in `ms`;
        limit: false,
        exclude: {
            query: false, // do not exclude query params
            filter: (req:any) => shouldCacheRequest(req), // exclude? if `true`, it does not cache
            methods: ['patch','put','delete'], // to include POST in cache
        },
        debug: false, // true = very verbose
    }
});

export const axiosInstanceHttp = setup({
    validateStatus: function (status) {
        return (status >= 200 && status < 300);
    },
    // `axios-cache-adapter` options
    cache: {
        maxAge: 0.05*(60*1000), // ms
        limit: false,
        exclude: {
            query: false,
        },
        debug: false,
    }
});

export const useAxiosLoader = () => {
    const [loadingCounter, setCounter] = useState(0);

    const interceptors = useMemo(() => {
        const inc = () => setCounter(loadingCounter + 1);
        const dec = () => setCounter(loadingCounter - 1);
        return ({
            request: (config:any) => (inc(), config),
            response: (response:any) => (dec(), response),
            error: (error:any) => (dec(), Promise.reject(error)),
        });
    }, []); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return [loadingCounter > 0];
};

export const useAxiosMessages = (): any => {
    const [messages, setMessages] = useState<any[]>([]);
    const [type, setType] = useState("error");

    const interceptors = useMemo(() => {
        const inc = () => setMessages([]);
        const dec = (res:any,type:VariantType) => {
            let messagesTemp:any[] = [];
            let typeTemp:VariantType = type;
            switch(type) {
                case 'success':
                    messagesTemp = res.data?.messages?.length?res.data.messages:[];
                    break;
                case 'error':
                    messagesTemp = res.response?.data?.messages?.length?res.response.data.messages:[];
                    break;
            }
            /* Laravel Validation Errors */
            /* Since this will always be an ERROR status code, data would be in res.response.data */
            if (typeof res?.response?.data?.errors !== 'undefined') {
                let laraValidationErrors: any[] = [];
                for (let key in res.response.data.errors) {
                    res.response.data.errors[key].forEach( (err:any) => {laraValidationErrors.push(err)} )
                }
                messagesTemp.push(...laraValidationErrors);
            }
            return (setType(typeTemp), setMessages(messagesTemp));
        }
        return ({
            request: (config:any) => (inc(), config),
            response: (response:any) => (dec(response,"success"),response),
            error: (error:any) => (dec(error,"error"),Promise.reject(error)),
        });
    }, []); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return {
        type: type,
        messages: messages||[]
    };
};

export default function ApiLoading(): any {
    const [loading] = useAxiosLoader();
    const message = useAxiosMessages();
    const { enqueueSnackbar } = useSnackbar();

    if(message && message.messages && message.messages.length) {
        message.messages.map( (m:string) => {
            if('success'===message.type) {
                enqueueSnackbar(m, {variant: 'success'});
            }
            else {
                enqueueSnackbar(m, {variant: 'error'});
            }
        });
    }
    /* Nprogress */
    loading?NProgress.start(): NProgress.done();
    /* Display */
    return (
        <>
        { loading &&
        <div style={{ zIndex: 10000, position: "absolute", top: 10, right: 10, padding: 0 }}>
            <CircularProgress size={20} thickness={3} color="primary" />
        </div>
        }
        </>
    );
}



/* References:
   Force-cache: https://lightrun.com/answers/rascarlito-axios-cache-adapter-making-the-cache-optional-ie-by-default-no-cache
*/
