// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

/* ======================================== */
/* Generic Paths */
/* ======================================== */
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

/* ======================================== */
/* Checklists / CL */
/* ======================================== */
const ROOTS_CHECKLISTS = '/checklist';
export const PATH_CHECKLISTS = {
  root: ROOTS_CHECKLISTS,
  dashboards: {
    summary: path(ROOTS_CHECKLISTS,'/dashboard')
  },
  manage: {
    items: path(ROOTS_CHECKLISTS,'/items'),
    comments: path(ROOTS_CHECKLISTS,'/comments'),
    schedules: path(ROOTS_CHECKLISTS,'/schedules'),
    instances: path(ROOTS_CHECKLISTS,'/instances'),
    checklists: path(ROOTS_CHECKLISTS,'/checklists'), // instances renamed to checklists
    locations: path(ROOTS_CHECKLISTS,'/cl-locations'),
    blueprints: path(ROOTS_CHECKLISTS,'/blueprints'),
    templates: path(ROOTS_CHECKLISTS,'/templates'), // blueprints renamed to templates
  },
  modify: {
    create: (type: string) => path(ROOTS_CHECKLISTS, `/${type}/create/`),
    edit: (type:string, id: string) => path(ROOTS_CHECKLISTS, `/${type}/edit/${id}`),
    view: (type:string, id: string) => path(ROOTS_CHECKLISTS, `/${type}/view/${id}`),
  }
}

/* ======================================== */
/* Feedback / FBS */
/* ======================================== */
const ROOTS_FBS = '/feedback';
export const PATH_FBS = {
  root: ROOTS_FBS,
  dashboards: {
    summary: path(ROOTS_FBS,'/dashboard'),
    analytics: path(ROOTS_FBS,'/analytics')
  },
  manage: {
    feedback: path(ROOTS_FBS,'/list'),
    locations: path(ROOTS_FBS,'/locations'),
    templates: path(ROOTS_FBS,'/templates'),
    locToStaffOnDuty: path(ROOTS_FBS,'/location-to-staff-on-duty-mapping'),
  },
  modify: {
    create: (type: string) => path(ROOTS_FBS, `/${type}/create/`),
    edit:   (type:string, id: string) => path(ROOTS_FBS, `/${type}/edit/${id}`),
    view:   (type:string, id: string) => path(ROOTS_FBS, `/${type}/view/${id}`),
  }
}

/* ======================================== */
/* Staff Deployment */
/* ======================================== */
const ROOTS_STAFF_DEPLOYMENT = '/staff-deployment';
export const PATH_STAFF_DEPLOYMENT = {
  root: ROOTS_STAFF_DEPLOYMENT,
  vendors: path(ROOTS_STAFF_DEPLOYMENT,'/vendors'),
  templates: path(ROOTS_STAFF_DEPLOYMENT,'/deployment-templates'),
  sheets: path(ROOTS_STAFF_DEPLOYMENT,'/deployment-sheets'),
  history: path(ROOTS_STAFF_DEPLOYMENT,'/history'),
}

/* ======================================== */
/* Shifts */
/* ======================================== */
const ROOTS_SHIFTS = '/shift';
export const PATH_SHIFTS = {
  root: ROOTS_SHIFTS,
  dashboards: {
    summary: path(ROOTS_SHIFTS,'/dashboard')
  },
  manage: {
    shifts: path(ROOTS_SHIFTS,'/shifts'),
  },
  modify: {
    create: (type: string) => path(ROOTS_SHIFTS, `/${type}/create/`),
    edit:   (type:string, id: string) => path(ROOTS_SHIFTS, `/${type}/edit/${id}`),
    view:   (type:string, id: string) => path(ROOTS_SHIFTS, `/${type}/view/${id}`),
  }
}

/* ======================================== */
/* Reporting */
/* ======================================== */const ROOTS_REPORTS = '/reports';
export const PATH_REPORTS = {
  root: ROOTS_REPORTS,
  download: path(ROOTS_REPORTS,'/download'),
}

/* ======================================== */
/* Users */
/* ======================================== */
const ROOTS_USERS = '/users';
export const PATH_USERS = {
  root: ROOTS_USERS,
  manage: path(ROOTS_USERS,'/manage'),
}

/* ======================================== */
/* Roles Management */
/* ======================================== */
const ROOTS_ROLES = '/role';
export const PATH_ROLES = {
  root: ROOTS_ROLES,
  list: path(ROOTS_ROLES,'/roles'),
  vesta: {
    dataManager: path(ROOTS_ROLES, `/vesta/data-manager`),
    mySettings: path(ROOTS_ROLES, `/vesta/my-settings`),
  },
  manage: {
    users: path(ROOTS_ROLES, `/manage/roles-users`),
  },
  modify: {
    create: (type: string) => path(ROOTS_ROLES, `/${type}/create/`),
    edit:   (type:string, id: string) => path(ROOTS_ROLES, `/${type}/edit/${id}`),
    view:   (type:string, id: string) => path(ROOTS_ROLES, `/${type}/view/${id}`),
  },
}

/* ======================================== */
/* Sub-Apps / Mini-apps */
/* ======================================== */
const ROOTS_APP = '/app';
export const PATH_APPS = {
  root: ROOTS_APP,
  qrCodeGen: path(ROOTS_APP,'/qr-code-generator'),
  bulkBatchCsvDataImporter: path(ROOTS_APP,'/bulk-csv-data-importer'),
  scheduledTasks: path(ROOTS_APP,'/scheduled-tasks'),
}

/* ======================================== */
/* Vesta, Requests Management */
/* ======================================== */
const ROOTS_REQ_MGMT = '/requests-mgmt';
export const PATH_REQ_MGMT = {
  root: ROOTS_REQ_MGMT,
  list: path(ROOTS_REQ_MGMT,'/list'),
  create: path(ROOTS_REQ_MGMT,'/create'),
  analytics: path(ROOTS_REQ_MGMT,'/analytics'),
  reports: path(ROOTS_REQ_MGMT,'/reports'),
  escalations: path(ROOTS_REQ_MGMT,'/esc-config'),
  depGroups: path(ROOTS_REQ_MGMT,'/dep-groups'),
  areaLocations: path(ROOTS_REQ_MGMT,'/area-locations'),
  departmentConfig: path(ROOTS_REQ_MGMT,'/manager'),

}

/* ======================================== */
/* Notifications Center */
/* ======================================== */
const ROOTS_NOTIF_CENTER = '/notifications-center';
export const PATH_NOTIF_CENTER = {
  root: ROOTS_NOTIF_CENTER,
  deptWiseEmail: path(ROOTS_NOTIF_CENTER,'/dept-wise-email'),
  notifLists: path(ROOTS_NOTIF_CENTER,'/notification-lists'),
}

/* ======================================== */
/* Org. Admin */
/* ======================================== */
const ROOTS_ADMIN = '/admin';
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  manageOrgs: path(ROOTS_ADMIN,'/manage/org'),
  manageDepts: path(ROOTS_ADMIN,'/manage/depts'),
  manageUsers: path(ROOTS_ADMIN,'/manage/users'),
  dataManager: path(ROOTS_ADMIN,'/dataManager'),
  orgConfig: path(ROOTS_ADMIN,'/org-config'),
  activityLogs:path(ROOTS_ADMIN,'/activity-logs')
}

/* ======================================== */
/* Super-Admin */
/* ======================================== */
const ROOTS_SUPER_ADMIN = '/super-admin';
export const PATH_SUPER_ADMIN = {
  root: ROOTS_SUPER_ADMIN,
  apps: {
    onboarding: path(ROOTS_SUPER_ADMIN,'/onboarding'),
    scheduler: path(ROOTS_SUPER_ADMIN,'/scheduler'),
  }
}

/* ======================================== */
/* Dev. Sandbox */
/* ======================================== */
const ROOTS_SANDBOX = '/sandbox';
export const PATH_SANDBOX = {
  root: ROOTS_SANDBOX,
  apps: {
    devSandBox: path(ROOTS_SANDBOX,'/dev-sandbox'),
    reactCSVImporter: path(ROOTS_SANDBOX,'/react-csv-importer'),
  }
}

// ******************** END OF VESTA APP **********************

// ============================================================
// Routes below this are part of the MUI THEME
// Keeping it here for quick code references
// ============================================================

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/home/about-us',
  contact: '/home/contact-us',
  faqs: '/home/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    checklists: path(ROOTS_DASHBOARD, '/checklists'),
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
