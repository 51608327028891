import { useTranslation } from 'react-i18next';
// @mui
import {enUS, deDE, frFR, hiIN} from '@mui/material/locale';
// ----------------------------------------------------------------------

const LANGUAGES_SUPPORTED = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: `/icons/uxwing/english-language-code.svg`,
  },
  {
    label: 'Hindi',
    value: 'hi',
    systemValue: hiIN,
    icon: `/icons/uxwing/hindi-language-code.svg`,
  },
  /*{
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: `/icons/uxwing/german-language-code.svg`,
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: `/icons/uxwing/fr-language-code.svg`,
  },*/
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGUAGES_SUPPORTED.find((_lang) => _lang.value === langStorage) || LANGUAGES_SUPPORTED[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLang: LANGUAGES_SUPPORTED,
  };
}
