import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import {
  PATH_ADMIN, PATH_APPS,
  PATH_REQ_MGMT,
  PATH_FBS,
  PATH_NOTIF_CENTER,
  PATH_REPORTS,
  PATH_ROLES,
  PATH_SHIFTS,
  PATH_USERS, PATH_SANDBOX,
  PATH_STAFF_DEPLOYMENT
} from "./paths";
import IntershipFSD from '../pages/misc/internship/IntershipFSD';
import DepartmentConfig from 'src/pages/request-management/DepartmentConfig';
import DeptManagement from '../pages/admin/DeptManagement';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([

    /* Match with paths.ts */

    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Internship Section
    {
      path: 'internship',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'full-stack-dev', element: <IntershipFSD /> },
      ],
    },

    // ROOT
    {
      path: '/',
      element: (<GuestGuard>
        <Login />
      </GuestGuard>),
    },

    // Requests
    {
      path: PATH_REQ_MGMT.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_REQ_MGMT.list} replace/>, index: true },
        { path: PATH_REQ_MGMT.list, element: <Requests item="request" key="request" feature="Request" /> },
        { path: PATH_REQ_MGMT.analytics, element: <Analytics item="request" key="request" feature="Request" /> },
        { path: PATH_REQ_MGMT.escalations, element: <EscalationsConfig/> },
        { path: PATH_REQ_MGMT.depGroups, element: <DepartmentGroups/> },
        { path: PATH_REQ_MGMT.areaLocations, element: <AreaLocations/> },
        { path: PATH_REQ_MGMT.departmentConfig, element: <DepartmentConfig/> },

      ]
    },

    // Checklists
    {
      path: 'checklist',
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'dashboard'} replace/>, index: true },
        { path: 'dashboard', element: <CLDashboardSummary /> },
        { path: 'blueprints',
          element: <GenericEntityLandingPage item="blueprints" key="blueprints" feature="Checklist" /> }, /* KEY is required for a unique instance of component */
        { path: 'templates',
          element: <GenericEntityLandingPage item="templates" key="templates" feature="Checklist"/> }, /* Same as 'blueprints' */
        { path: 'items',
          element: <GenericEntityLandingPage item="items" key="items" feature="Checklist" /> },
        { path: 'comments',
          element: <GenericEntityLandingPage item="comments" key="comments" feature="Checklist" /> },
        { path: 'cl-locations',
          element: <GenericEntityLandingPage item="cl-locations" key="cl-locations" feature="Checklist" /> },
        { path: 'instances',
          element: <GenericEntityLandingPage item="instances" key="instances" feature="Checklist" /> },
        { path: 'checklists',
          element: <GenericEntityLandingPage item="checklists" key="checklists" feature="Checklist" /> }, /* Same as 'instances' */
        { path: 'schedules',
          element: <GenericEntityLandingPage item="schedules" key="schedules" feature="Checklist" /> },
        { path: ':entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-entity" feature="Checklist" /> },
        { path: ':entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-entity" feature="Checklist" /> },
      ]
    },

    // Feedback - FBS
    {
      path: 'feedback',
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'dashboard'} replace/>, index: true },
        { path: 'dashboard', element: <FBSDashboardSummary key="fbs-dashboard" feature="Feedback" /> },
        { path: 'analytics', element: <FBSDashboardAnalytics key="fbs-analytics" feature="Feedback" /> },
        { path: 'list', element: <GenericEntityLandingPage item="feedback" key="fbs-feedback" feature="Feedback" /> },
        { path: 'locations', element: <GenericEntityLandingPage item="locations" key="fbs-locations" feature="Feedback" /> },
        { path: 'templates', element: <GenericEntityLandingPage item="templates" key="fbs-templates" feature="Feedback" /> },
        { path: ':entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-entity-fbs" feature="Feedback" /> },
        { path: ':entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-entity-fbs" feature="Feedback" /> },
        /* Staff on shift/duty mapping */
        { path: PATH_FBS.manage.locToStaffOnDuty, element: <FBSLocToStaffShiftMapping /> },
      ],
    },

        // Staff Deployment
        {
          path: PATH_STAFF_DEPLOYMENT.root,
          element: (
              <AuthGuard>
                <DashboardLayout/>
              </AuthGuard>
          ),
          children: [
            { path: PATH_STAFF_DEPLOYMENT.vendors, element: <GenericEntityLandingPage item="vendor" key="vendor" feature="Vendor" /> },
            { path: `${PATH_STAFF_DEPLOYMENT.vendors}/create`, element: <Vendor/> },
            { path: `${PATH_STAFF_DEPLOYMENT.vendors}/edit/:id`, element:<Vendor/>  },
            { path: PATH_STAFF_DEPLOYMENT.templates, element: <GenericEntityLandingPage item="Deployment-Template" key="Deployment-Template" feature="Deployment-Template" /> },
            { path: ':entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-entity" feature="Deployment-Template" /> },
            { path: ':entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-entity" feature="Deployment-Template" /> },
            // sheets
            { path: PATH_STAFF_DEPLOYMENT.sheets, element: <GenericEntityLandingPage item="Deployment" key="Deployment" feature="Deployment" /> },
            { path: `${PATH_STAFF_DEPLOYMENT.sheets}/create`, element: <DeploymentSheet/> },
            { path: `${PATH_STAFF_DEPLOYMENT.sheets}/edit/:id`, element:<DeploymentSheet/>  },
            //History
            { path: PATH_STAFF_DEPLOYMENT.history, element: <GenericEntityLandingPage item="Deployment Record" key="Deployment Record" feature="Staff Deployment" /> },
          ],
        },

    // Roles
    {
      path: PATH_ROLES.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_ROLES.root+'/roles'} replace/>, index: true},
        { path: 'roles', element: <GenericEntityLandingPage item="roles" key="roles" feature="Roles" /> },
        { path: 'roles-users', element: <GenericEntityLandingPage item="roles" key="roles" feature="Roles" /> }, /* Duplicated for fixing breadcrumbs @ Roles-users */
        { path: 'manage/:entity', element: <GenericEntityCreateOrEdit action="manage" key="roles-user-manage" feature="Roles" /> },
        { path: ':entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-entity-role" feature="Roles" /> },
        { path: ':entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-entity-role" feature="Roles" /> },
        /* TEMP */
        { path: 'vesta/data-manager', element: <IframeDisplay displayKey="old.dataManager" key="old.dataManager" /> },
        { path: 'vesta/my-settings', element: <IframeDisplay displayKey="old.mySettings" key="old.mySettings" /> }
      ]
    },

    // Users
    {
      path: PATH_USERS.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_USERS.root} replace/>, index: true},
        { path: 'manage', element: <ManageUsers key="users-manage" feature="Users" /> },
      ]
    },

    // Shifts
    {
      path: PATH_SHIFTS.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_SHIFTS.manage.shifts} replace/>, index: true },
        { path: 'shift', element: <GenericEntityLandingPage item="shift" key="shift" feature="Shift" /> },
        { path: 'shifts', element: <GenericEntityLandingPage item="shift" key="shift" feature="Shift" /> },
        { path: ':entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-entity-shift" feature="Shift" /> },
        { path: ':entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-entity-shift" feature="Shift" /> },
      ]
    },

    // Reports
    {
      path: PATH_REPORTS.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_REPORTS.root} replace/>, index: true},
        { path: PATH_REPORTS.download, element: <ReportingDownload /> },
      ]
    },

    // Notifications Center
    {
      path: PATH_NOTIF_CENTER.root,
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_NOTIF_CENTER.root} replace/>, index: true},
        { path: PATH_NOTIF_CENTER.deptWiseEmail, element: <EmailDeptWiseManager/> },
        { path: `${PATH_NOTIF_CENTER.notifLists}/create`, element: <NotificationLists/> },
        { path: `${PATH_NOTIF_CENTER.notifLists}/edit/:id`, element: <NotificationLists/> },
        { path: PATH_NOTIF_CENTER.notifLists, element: <GenericEntityLandingPage item="notificationList" key="notificationList" feature="NotificationList" /> },
      ]
    },

    // Admin / Org. Admin
    {
      path: "admin",
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to='admin' replace />, index: true},
        { path: PATH_ADMIN.manageOrgs, element: <OrgManagement/> },
        { path: PATH_ADMIN.manageDepts, element: <DeptManagement/> },
        { path: PATH_ADMIN.manageUsers, element: <UserTools/> },
        { path: PATH_ADMIN.dataManager, element: <DataManager/> },
        { path: PATH_ADMIN.orgConfig, element: <OrgConfig/> },
        { path: PATH_ADMIN.activityLogs, element: <ActivityLogs/> },
      ]
    },

    // Super-Admin
       {
        path: "super-admin",
        element: (
            <AuthGuard>
              <DashboardLayout/>
            </AuthGuard>
        ),
        children: [
          {element: <Navigate to='super-admin' replace/>, index: true},
        ]
      },

    // Apps/Modules
    {
      path: 'app',
      element: (
          <AuthGuard>
            <DashboardLayout/>
          </AuthGuard>
      ),
      children: [
        {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
        { path: PATH_APPS.qrCodeGen, element: <QRCodeGenerator /> },
        { path: PATH_APPS.bulkBatchCsvDataImporter, element: <ReactCSVImporter /> },
        { path: PATH_APPS.scheduledTasks, element: <GenericEntityLandingPage item="scheduledTasks" key="scheduledTask" feature="scheduledTask" /> },
        { path: PATH_APPS.root+'/:entity/create/', element: <GenericEntityCreateOrEdit action="create" key="create-st" feature="scheduledTask" /> },
        { path: PATH_APPS.root+'/:entity/edit/:id', element: <GenericEntityCreateOrEdit action="edit" key="edit-st" feature="scheduledTask" /> },
      ]
    },

    // Dev. Sandbox
    {
      path: 'sandbox',
      element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true },
        { path: PATH_SANDBOX.apps.devSandBox, element: <DevSandbox /> },
        { path: PATH_SANDBOX.apps.reactCSVImporter, element: <ReactCSVImporter /> },
      ]
    },

    // ******************** END OF VESTA APP **********************

    // ============================================================
    // Routes below this are part of the MUI THEME
    // Keeping it here for quick code references
    // ============================================================

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/home',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },

    /* 404 Catch-all */
    { path: '*', element: <Navigate to="/404" replace /> },

  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// USERS
const ManageUsers = Loadable(lazy(() => import('../pages/users/ManageUsers')));

// CHECKLISTS
const CLDashboardSummary = Loadable(lazy(() => import('../pages/checklist/DashChecklistsSummary')));
const GenericEntityLandingPage = Loadable(lazy(() => import('../pages/dynamic-components/GenericEntityLandingPage')));
const GenericEntityCreateOrEdit = Loadable(lazy(() => import('../pages/dynamic-components/GenericEntityCreateOrEdit')));
const IframeDisplay = Loadable(lazy(() => import('../pages/dynamic-components/IframeDisplay')));
const RoleUserEdit = Loadable(lazy(() => import('../pages/roles/RoleUserEdit')));

// VESTA Request Management
const Requests = Loadable(lazy(() => import('../pages/request-management/Requests')));
const Analytics = Loadable(lazy(() => import('../pages/request-management/Analytics')));
const EscalationsConfig = Loadable(lazy(() => import('../pages/request-management/EscalationsConfig')));
const DepartmentGroups = Loadable(lazy(() => import('../pages/request-management/DepartmentGroups')));
const AreaLocations = Loadable(lazy(() => import('../pages/request-management/AreaLocations')));

// REPORTING
const ReportingDownload = Loadable(lazy(() => import('../pages/reporting/DownloadReports')));

// Notifications Center
const EmailDeptWiseManager = Loadable(lazy(() => import('../pages/notifications-center/EmailDeptWiseManager')));
const NotificationLists = Loadable(lazy(() => import('../pages/notifications-center/NotificationList')));

// Staff Deployment
const Vendor = Loadable(lazy(() => import('../pages/staff-deployment/Vendor')));
const DeploymentSheet = Loadable(lazy(() => import('../pages/staff-deployment/DeploymentSheet')));

// SUPER ADMIN
const OrgManagement = Loadable(lazy(() => import('../pages/admin/OrgManagement')));
const DataManager = Loadable(lazy(() => import('../pages/admin/DataManager')));
const OrgConfig = Loadable(lazy(() => import('../pages/admin/OrgConfig')));
const UserTools = Loadable(lazy(() => import('../pages/admin/UserTools')));
const ActivityLogs=Loadable(lazy(() => import('../pages/admin/ActivityLogs')));

// QR Code Generator
const QRCodeGenerator = Loadable(lazy(() => import('../pages/checklist/QRCodeGenerator')));

// FBS: Feedback System
const FBSDashboardSummary = Loadable(lazy(() => import('../pages/checklist/DashFeedbackSummary')));
const FBSDashboardAnalytics = Loadable(lazy(() => import('../pages/checklist/DashFeedbackAnalytics')));
const FBSLocToStaffShiftMapping = Loadable(lazy(() => import('../pages/fbs/FbsLocationToStaffUserShiftMapping')))

// Sandbox
const DevSandbox = Loadable(lazy(() => import('../pages/sandbox/DevSandbox')));
const ReactCSVImporter = Loadable(lazy(() => import('../pages/dynamic-components/importer/ReactCSVImporter')));


// =====================================================================================================================
// Code below this are part of the MUI THEME
// Keeping it here for quick code references
// =====================================================================================================================
// STOP; Do not edit below this line
// =====================================================================================================================

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));

// COMMON/SHARED
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
