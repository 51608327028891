import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <div style={{ background: 'linear-gradient(360deg, rgba(239,239,239,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 100%)' }}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        { mdUp ?
          <SectionStyle>
            <Typography variant="h4" sx={{ px: 5, mt: 10, fontWeight:600 }}>
              <span>Hi!</span> welcome to Vesta.
            </Typography>
            <Typography variant="h6" sx={{ px: 5, mb: 1, color: 'grey.500', fontWeight:400 }}>
              Login to start managing your org.
            </Typography>
            <Stack justifyContent="center" alignItems="center">
              {/*<Iconify icon="icon-park:hi" width={256} height={256} />*/}
              <img src="/assets/illustrations/bloom-login-sec.png" alt="" />
            </Stack>
            {/*<img src="https://cdn.dribbble.com/users/332742/screenshots/16482684/media/d5f9f777aae7639577c70353e439493f.gif" alt="" style={{width: '100%'}}/>*/}
            {/*<div style={{display:'flex', justifyContent: "flex-end", position: 'relative',height: '400px'}}>
            <video autoPlay={true} loop={true} className={'login-video'} style={{width:'300px',height: '300px', position: 'absolute', marginRight: -10}}>
              <source src="https://res.cloudinary.com/upp/video/upload/v1647673238/Animations/hello-de-art-studio.mp4" type="video/mp4" />
            </video>
            </div>*/}
          </SectionStyle>
        : <></>
        }

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details to continue</Typography>
              </Box>

              {/*<Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip>*/}
            </Stack>

            {/*<Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>demo@test.com</strong> / password :<strong> demo1234</strong>
            </Alert>*/}

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
      </div>
    </Page>
  );
}
