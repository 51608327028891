import React from 'react';
// @mui
import {Box, Select, MenuItem, FormControl } from '@mui/material';
// utils
import useAuth from "../../../hooks/useAuth";
import {axiosInstance as axios} from "../../../utils/axios";

// ----------------------------------------------------------------------
type Props = {
    isCollapsed: boolean | undefined;
};

export default function AtomOrgSwitcher({ isCollapsed }: Props) {

  const { user } = useAuth();

  const handleChange = (e:any) => {
      let data = {
          hotel_id: e.target.value as string,
      }
      axios.post('/user/org-associations-set',data).then(()=>{
          window.location.reload();
      });
  };

  return (
    <Box sx={{opacity: isCollapsed?0:1}}>
        <FormControl fullWidth>
            <Select
                id="org-switcher"
                value={user?.hotel?.id}
                placeholder="Org. Switcher"
                onChange={handleChange}
                fullWidth
                size={'small'}
            >
                {user?.hotels?.map((org:any,index:number) => (
                    <MenuItem key={index} value={org.id}>{org.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    </Box>
  );
}
