import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Stack,
  Radio,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { axiosInstance as axios } from '../../utils/axios';
import useAuth from '../../hooks/useAuth';


interface Dept {
  id: string;
  desk: string;
}

interface Item {
  id: string;
  name: string;
  desc: string;
  resolution_time: string;
  resolution_time_parked: string;
  desk_id: string;
  desk_id_new?: string;
}

const Department = () => {
  const [departmentObj, setDepartmentObj] = useState<Dept>({ id: '', desk: '' });
  const [newDeskAdded, setNewDeskAdded] = useState<boolean>(false);
  const [department, setDepartment] = useState<Dept[]>([]);
  const [deskNameAppendPrependMode, setDeskNameAppendPrependMode] = useState<string>('append');
  const [deskNameAppendPrepend, setDeskNameAppendPrepend] = useState<string>('');
  const [showAppendPrependOptions, setShowAppendPrependOptions] = useState<boolean>(false);
  const { user } = useAuth();

  const [showAddDeskForm, setShowAddDeskForm] = useState<boolean>(false);
  const [selectedDeskId, setSelectedDeskId] = useState<string>('');

  const [items, setItems] = useState<Item[]>([]);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [newDeskName, setNewDeskName] = useState<string>('');

  useEffect(() => {
    if (user?.hotels) {
      const hotelId = user.hotels[0].id;
      getDesks(hotelId);
    }
  }, []);

  const handleDeptObj = (
    event: React.ChangeEvent<{}>,
    selectedValue: Dept | null
  ) => {
    if (selectedValue) {
      setDepartmentObj(selectedValue);
      fetchItemsForDesk(selectedValue.id);
    } else {
      setDepartmentObj({ id: '', desk: '' });
    }
  };

  const fetchItemsForDesk = (deskId: string) => {
    axios
      .get(`/hotel/desk/${deskId}/items`)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error('Error fetching items for desk:', error);
      });
  };

  const getDesks = (hotelId: string) => {
    axios
      .get(`/hotel/${hotelId}/desks`)
      .then((response) => {
        console.log('Desks fetched:', response.data);
        const desks = response.data.map((desk: any) => ({ id: desk.id, desk: desk.name }));
        setDepartment(desks);
      })
      .catch((error) => {
        console.error('Error getting desks:', error);
      });
  };

  const handleAddDesk = () => {
    if (newDeskName.trim()) {
      axios
        .post(`/admin/desk`, { hotel_id: user?.hotel_id, desk_name: newDeskName })
        .then((response) => {
          console.log('Desk added:', response.data, newDeskName, user?.hotel_id);
          setNewDeskAdded(true);
          getDesks(user?.hotel_id);
          setShowAddDeskForm(false);
          setNewDeskName('');
        })
        .catch((error) => {
          console.error('Error adding desk:', error);
        });
    }
  };

  const deleteDesk = (deskId: string) => {
    if (window.confirm(`Warning! This will DELETE the desk with id: ${deskId} from your organization`)) {
      axios
        .delete(`/admin/desk/${deskId}`)
        .then(() => {
          console.log('Desk deleted with id:', deskId);
          setDepartmentObj({ id: '', desk: '' });
          //setNewDeskAdded(false);
          getDesks(user?.hotel_id);
        })
        .catch((error) => {
          console.error('Error deleting desk:', error);
        });
    }
  };

  const handleAddItem = () => {
    if (!departmentObj.id) {
      console.error('Cannot add item without selecting a department');
      return;
    }
    const newItemId = `new-${Date.now()}`;
    const newItem: Item = {
      id: newItemId,
      name: '',
      desc: '',
      resolution_time: '',
      resolution_time_parked: '',
      desk_id: departmentObj.id,
    };

    setItems([...items, newItem]);

    // Set the editingItemId to the ID of the newly added item
    setEditingItemId(newItemId);
  };

  const handleDepartmentChange = (newValue: Dept | null, itemId: string) => {
    if (newValue) {
      const updatedItems = items.map((item) =>
        item.id === itemId ? { ...item, desk_id_new: newValue.id } : item
      );
      setItems(updatedItems);
    }
  };

  const handleAppendPrependText = () => {
    const updatedItems = items.map((item) => {
      const appendedText =
        deskNameAppendPrependMode === 'prepend'
          ? `${deskNameAppendPrepend} ${item.name}`
          : `${item.name} ${deskNameAppendPrepend}`;
      return { ...item, name: appendedText };
    });
    setItems(updatedItems);

    const requestdata = {
      hotel_id: user?.hotels[0].id, // Use the first hotel's ID from the user object
      desk_id: departmentObj.id, // Use the selected department's ID
      mode: deskNameAppendPrependMode,
      str: deskNameAppendPrepend,
    };
    console.log('append data', requestdata);

    if (window.confirm('Are you sure? There is no going back.')) {
      axios
        .post('/admin/desk-name-append-prepend', requestdata) // Corrected route here
        .then((response) => {
          console.log('Items updated successfully:', response.data);
          // Once done, you can close the options
          setShowAppendPrependOptions(false);
        })
        .catch((error) => {
          console.error('Error updating items:', error);
        });
    }
  };

  const handleCloseAppendPrependOptions = () => {
    setShowAppendPrependOptions(false);
  };

  const handleShowAppendPrependOptions = () => {
    setShowAppendPrependOptions(true);
  };

  const handleEdit = (id: string) => {
    setEditingItemId(id);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    itemId: string
  ) => {
    const updatedItems = items.map((item) =>
      item.id === itemId ? { ...item, [key]: event.target.value } : item
    );
    setItems(updatedItems);
  };

  const saveItem = (itemId: string) => {
    const itemToSave = items.find((item) => item.id === itemId);
    if (!itemToSave) return;

    axios
      .post(`/admin/item`, {
        ...itemToSave,
        desk_id: departmentObj.id,
        hotel_id: user?.hotels[0].id,
      })
      .then((response) => {
        fetchItemsForDesk(departmentObj.id);
        setEditingItemId(null);
      })
      .catch((error) => {
        console.error('Error saving item:', error);
      });
  };

  const removeItem = (itemId: string) => {
    if (window.confirm('Warning! This will PERMANENTLY DELETE this Item')) {
      axios
        .delete(`/admin/item/${itemId}`)
        .then(() => {
          const updatedItems = items.filter((item) => item.id !== itemId);
          setItems(updatedItems);
        })
        .catch((error) => {
          console.error('Error deleting item:', error);
        });
    }
  };

  return (
    <Box>
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h3" sx={{ marginBottom: 2 }}>
      <Icon icon="fa6-solid:id-card" style={{}} /> Departments
    </Typography>

    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
      <Autocomplete
        options={department}
        getOptionLabel={(option) => option.desk}
        value={departmentObj}
        onChange={handleDeptObj}
        isOptionEqualToValue={(option, value) => option.id === value.id && option.desk === value.desk}
        renderInput={(params) => (
          <TextField {...params} label="Select Your Department" color="secondary" />
        )}
        sx={{ width: 300 }}
      />
      <Button
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => setShowAddDeskForm(true)}
      >
        <Icon icon="openmoji:department-store" /> Add Department
      </Button>
      {departmentObj.id && (
        <Button
          size="medium"
          variant="contained"
          color="error"
          onClick={() => deleteDesk(departmentObj.id)}
        >
          Delete Department
        </Button>
      )}
    </Box>
    <Typography variant="body1">Selected Department is: {departmentObj.desk}</Typography>

    <hr style={{ width: '100%', margin: '20px 0 20px', border: 0, borderTop: '1px solid #eee' }} />
  </Box>

  {showAddDeskForm && (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Enter the new department name:
      </Typography>
      <TextField value={newDeskName} onChange={(e) => setNewDeskName(e.target.value)} fullWidth />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
        <Button onClick={() => setShowAddDeskForm(false)}>Cancel</Button>
        <Button onClick={handleAddDesk} sx={{ marginLeft: 2 }}>
          Save
        </Button>
      </Box>
    </Box>
  )}

  {newDeskAdded && <Typography variant="body1">New Department added to list</Typography>}

  <Box sx={{ maxWidth: 800, paddingBottom: 3 }}>
    <div className="dept-items">
      <Typography variant="h4" gutterBottom>
        <Icon icon="line-md:list-3-filled" /> Task/Snag{' '}
        <span style={{ fontSize: '16px', color: '#888' }}>
          for Department (id: {departmentObj.id}) @ {user?.hotels[0].name} {user?.hotels[0].location} (Hotel_id: {user?.hotel_id})
        </span>
      </Typography>

      {!departmentObj.id && (
        <div>
          <Typography variant="body1">Select a Department to see its items</Typography>
        </div>
      )}

      {departmentObj.id && (
        <div>
          <Button size="medium" variant="contained" color="primary" onClick={handleAddItem}>
            <Icon icon="line-md:list-3-filled" /> Add Task/Snag
          </Button>
        </div>
      )}
    </div>
  </Box>

  <Box sx={{ marginTop: 4 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Task/Snag</TableCell>
          <TableCell>Pending Escalation Time <br/> [In Minutes]</TableCell>
          <TableCell>Parked Escalation Time <br/> [In Minutes]</TableCell>
          <TableCell>Change Department <br/> [Move item]</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      {departmentObj.id && (
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              {editingItemId === item.id ? (
                <TextField
                  value={item.name}
                  onChange={(e) => handleInputChange(e, 'name', item.id)}
                  required
                />
              ) : (
                item.name
              )}
            </TableCell>
            <TableCell>
              {editingItemId === item.id ? (
                <TextField
                  value={item.resolution_time}
                  onChange={(e) => handleInputChange(e, 'resolution_time', item.id)}
                />
              ) : (
                item.resolution_time
              )}
            </TableCell>
            <TableCell>
              {editingItemId === item.id ? (
                <TextField
                  value={item.resolution_time_parked}
                  onChange={(e) => handleInputChange(e, 'resolution_time_parked', item.id)}
                />
              ) : (
                item.resolution_time_parked
              )}
            </TableCell>
            <TableCell>
              {editingItemId === item.id ? (
                <Autocomplete
                  value={department.find((option) => option.id === item.desk_id_new) || null}
                  onChange={(event, newValue) => handleDepartmentChange(newValue, item.id)}
                  options={department}
                  getOptionLabel={(option) => option.desk}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Your Department" color="secondary" />
                  )}
                  sx={{ width: 300 }}
                />
              ) : selectedDeskId ? (
                departmentObj?.desk
              ) : (
                'Default Department'
              )}
            </TableCell>
            <TableCell>
              {editingItemId === item.id ? (
                <>
                  <Button onClick={() => saveItem(item.id)}>Save</Button>
                  <Button onClick={() => setEditingItemId(null)}>Cancel</Button>
                </>
              ) : (
                <>
                  <Button onClick={() => handleEdit(item.id)}>
                    <Icon icon="line-md:edit-twotone" style={{ fontSize: '24px' }} />
                  </Button>
                  <Button onClick={() => removeItem(item.id)}>
                    <Icon icon="typcn:delete" style={{ fontSize: '24px' }} />
                  </Button>
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      )}
    </Table>
  </Box>

  {departmentObj.id && (
        <Box sx={{ marginTop: 4 }}>
          <Button size="medium" variant="contained" color="primary" onClick={handleAddItem}>
            <Icon icon="line-md:list-3-filled" /> Add Task/Snag
          </Button>
        </Box>
    )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 4 }}>
        <Button
          size="medium"
          variant="outlined"
          color="primary"
          onClick={handleShowAppendPrependOptions}
        >
          Append / Prepend text to Items
        </Button>
      </Box>

  {showAppendPrependOptions && (
    <Box
      sx={{
        marginTop: 2,
        border: '1px solid #ccc',
        padding: '10px',
        backgroundColor: '#f8d7da', // Background color for danger alert
        borderRadius: '4px', // Rounded corners
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Box shadow for depth
      }}
      className="fadeIn ng-pristine ng-invalid ng-invalid-required"
    >
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        DESK NAME APPENDER: USE WITH CAUTION!!! SuperAdmins only
      </Typography>
      <Typography variant="body1" gutterBottom>
        This permanently <u>appends a string to each item</u> in this department. There is no
        going back.
      </Typography>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 1 }}>
        <Radio
          checked={deskNameAppendPrependMode === 'prepend'}
          onChange={() => setDeskNameAppendPrependMode('prepend')}
        />
        <Typography variant="body1">Prepend</Typography>
        <Radio
          checked={deskNameAppendPrependMode === 'append'}
          onChange={() => setDeskNameAppendPrependMode('append')}
        />
        <Typography variant="body1">Append</Typography>
      </Stack>
      <TextField
        type="text"
        value={deskNameAppendPrepend}
        onChange={(e) => setDeskNameAppendPrepend(e.target.value)}
        required
        label="Append/Prepend Text"
        variant="outlined"
        sx={{ marginBottom: 1 }}
      />
      <Button onClick={handleAppendPrependText} variant="contained" color="error">
        SET
      </Button>
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        Here's how it will look:
      </Typography>
      <ul style={{ listStyleType: 'none' }}>
        {departmentObj.id &&
          department
            .slice(0, 3)
            .map((item) => (
              <li key={item.id}>
                {deskNameAppendPrependMode === 'prepend'
                  ? `${deskNameAppendPrepend} ${item.desk}`
                  : `${item.desk} ${deskNameAppendPrepend}`}
              </li>
            ))}
      </ul>
      <Button component="button" variant="text" onClick={handleCloseAppendPrependOptions}>
        Close [x]
      </Button>
    </Box>
  )}
</Box>

  );
};

export default Department;
