import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_STAFF_DEPLOYMENT } from '../routes/paths';


// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated,user } = useAuth();

  // For Vendor Supervisor login: role=3 
  if(isAuthenticated && user?.role==3){
    return  <Navigate to={PATH_STAFF_DEPLOYMENT.sheets} />
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
