import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[]; // Roles passed - roles for which
  children: React.ReactNode;
};

export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuth();
  const userRole = user?.role ?? 1;

  let currentUsersRoles: any[] = ['user'];
  /* Sup3rAdm1n */
  if(userRole>=10) {
    currentUsersRoles.push('vesta-super-admin');
    if([
      'think.dj@gmail.com','dj@checkinn.co',
      'aniketsinha5552@gmail.com',
    ].includes(user?.email)) currentUsersRoles.push('dev-thinkdj-superadmin');
  }
  /* Org Admin */
  if(userRole>=5) currentUsersRoles.push('admin');
  /* Manager */
  if(userRole>=2) currentUsersRoles.push('manager');

  /* Vendor Supervisor */
  if(userRole==3) currentUsersRoles= ['vendor-supervisor']

  if ((typeof roles === 'undefined')) return <>{children}</>
  else if ( (typeof roles !== 'undefined') && currentUsersRoles.includes(roles[0]) ) {  //(currentUsersRoles.some((v:string) => roles.indexOf(v) !== -1))
    return hasContent ? (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    ) : <>{children}</>;
  }

  return <></>;

}
